import DropdownMenu from "./DropdownMenu";
import { Link } from 'react-router-dom';


export const Home = () => {
    return (

        <img src="roronoa-zoro-one-1242x2208-10523.png" className="w-full h-auto" />

    );
}