// ContactPage.tsx

import React, { useState } from 'react';

const Contact: React.FC = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Traitement du formulaire (par exemple, envoi à une API ou affichage d'un message)
        console.log(formData);
    };

    return (
        <div className="container p-4 mx-auto mt-10">

            <div className="service-section">
                <h2>Services de Personnalisation de Sites Internet & Coaching en StreetLifting</h2>
                <p>Pour des solutions adaptées à vos besoins spécifiques.</p>
                <p>
                    📌 Instagram : <a href="https://www.instagram.com/massi_stl" target="_blank" rel="noopener noreferrer">@massiSTL</a>
                </p>
                <p>
                    📩 <a href="mailto:issam.toure01@gmail.com">issam.toure01@gmail.com</a>
                </p>
            </div>

            <br />

            <div className="service-section">
                <h2>site réalisé en collaboration avec Thomas Bossuet</h2>
                <p>Powerlifting</p>
                <p>
                    📌 Instagram : <a href="https://www.instagram.com/thomasb2st" target="_blank" rel="noopener noreferrer">@thomasb2st</a>
                </p>
                <p>
                    📩 <a href="mailto:Bossuetthomas@gmail.com">Bossuetthomas@gmail.com</a>
                </p>
            </div>

            <img src="roronoa-zoro-one-1242x2208-10523.png" className="w-full h-auto" />

        </div >
    );
};

export default Contact;
