import { NavLink } from "react-router-dom";
import DropdownMenu from "./DropdownMenu";


const Toolbars = () => {

    return (
        <div className="w-full p-4 bg-black shadow-md">
            <div className="container mx-auto">
                <div className="flex items-center justify-between">
                    <NavLink to="/">
                        <img src="IMG_1362.jpg" className="top-0 left-0 flex-grow object-cover w-20 h-auto mr-3 text-center" />
                    </NavLink>
                    <div className="flex w-full ml-6 space-x-4 text-white">
                        {/* Notez l'ajout de w-full et ml-6 ici */}

                        <NavLink className='flex-grow text-center' style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/Charge'>MAX</NavLink>
                        <NavLink className='flex-grow text-center' style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/Inverse'>CHARGE</NavLink>
                        <NavLink className='flex-grow text-center' style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : 'normal' })} to='/Contact'>Contact</NavLink>


                        {/* Notez l'ajout de flex-grow et text-center ici */}
                    </div>

                </div>
            </div>
        </div>

    );
}
export default Toolbars;
