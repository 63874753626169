import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Home } from './screens/Home';
import DropdownMenu from './screens/DropdownMenu';
import { Routes, BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import Calculator from './screens/Calculator';
import Toolbars from './screens/Toolbars';
import Contact from './screens/Contact';

function App() {
  return (
    <div className="min-h-screen text-white bg-black">

      <Router>
        <Toolbars />
        <nav className='flex justify-end'>
        </nav>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Charge' element={<Calculator caller="charge" />} />
          <Route path='/Inverse' element={<Calculator caller="Max" />} />
          <Route path='/Contact' element={<Contact />} />

        </Routes>
      </Router>
    </div >


  );
}

export default App;
