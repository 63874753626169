import NumberInput from "./NumberInput";
import React, { useState, useEffect } from 'react';
import DropdownMenu from "./DropdownMenu";
import DropdownNumber from "./DropdownNumber";


interface CalculatorProps {
    caller: string;
}


const matrice = [
    [100, 97.8, 95.5, 93.9, 92.2, 90.7, 89.2, 87.8, 86.3],
    [95.5, 93.9, 92.2, 90.7, 89.2, 87.8, 86.3, 85, 83.7],
    [92.2, 90.7, 89.2, 87.8, 86.3, 85, 83.7, 82.4, 81.1],
    [89.2, 87.8, 86.3, 85, 83.7, 82.4, 81.1, 79.9, 78.6],
    [86.3, 85, 83.7, 82.4, 81.1, 79.9, 78.6, 77.4, 76.2],
    [83.7, 82.4, 81.1, 79.9, 78.6, 77.4, 76.2, 75.1, 73.9],
    [81.1, 79.9, 78.6, 77.4, 76.2, 75.1, 73.9, 72.3, 70.7],
    [78.6, 77.4, 76.2, 75.1, 73.9, 72.3, 70.7, 69.4, 68.3]
];

interface MyObject {
    [key: number]: number;

}

const tableau: MyObject = {
    10: 0,
    9.5: 1,
    9: 2,
    8.5: 3,
    8: 4,
    7.5: 5,
    7: 6,
    6.5: 7,
    6: 8
};


function getMatrixValue(rpe: number, rep: number, max: number) {
    if (rpe < 6 || rpe > 10) {
        return "valeur de RPE :  6 à 10";
    }
    if (rep > 8 || rep < 0) {
        return "valeur de rep : 1 à 8"
    }
    var new_rpe = tableau[rpe]
    if (new_rpe == null) {
        return "RPE inconnu"
    }
    if (matrice[rep] && matrice[rep][new_rpe]) {
        const result = ((matrice[rep][new_rpe] / 100)) * max;
        return parseFloat(result.toFixed(2));
    }
    return <p className="p-2 m-4 text-xl font-semibold text-red-700 shadow-md"> </p>
}

function getMaxestimer(charge: number, rpe: number, rep: number) {
    if (charge < 0) {
        return "charge negative";
    }

    if (rpe < 6 || rpe > 10) {
        return "valeur de RPE :  6 à 10";
    }

    if (rep > 8 || rep < 0) {
        return "valeur de rep : 1 à 8"
    }

    var new_rpe = tableau[rpe]
    if (new_rpe == null) {
        return "RPE inconnu"
    }
    if (matrice[rep] && matrice[rep][new_rpe]) {
        const result = charge / (matrice[rep][new_rpe] / 100);
        return parseFloat(result.toFixed(2));

    }
    return <p className="p-2 m-4 text-xl font-semibold text-red-700 shadow-md"> </p>
}


const Calculator: React.FC<CalculatorProps> = ({ caller }) => {
    useEffect(() => {
        console.log(`Called by ${caller}`);

    }, [caller]);

    const [showExplanation, setShowExplanation] = useState(false);


    const [enteredText, setEnteredText] = useState<string>('');
    const [enteredText2, setEnteredText2] = useState<string>('');
    const [enteredText3, setEnteredText3] = useState<string>('');


    if (caller === 'Max') {

        return (
            <div className="App">
                {showExplanation && (
                    <div className="p-4 m-4 text-black bg-red-300 rounded-full shadow">
                        Cette page vous offre l'outil nécessaire pour
                        <br /> déterminer la charge de travail adéquate en fonction
                        <br />d'un nombre précis de répétitions et d'un RPE, en utilisant votre max comme référence.

                    </div>
                )}
                <h2 className="p-2 m-4 text-xl font-semibold text-red-700 shadow-md">MAX  <button
                    className="p-1 ml-2 bg-gray-200 rounded-full hover:bg-gray-300 focus:outline-none"
                    onClick={() => setShowExplanation(!showExplanation)}
                >
                    ?
                </button>
                </h2>
                <NumberInput onTextChange={(text3) => setEnteredText3(text3)} />
                <h2 className="p-2 m-4 text-xl font-semibold text-red-700 shadow-md">Nombre de répétition</h2>
                <NumberInput onTextChange={(text2) => setEnteredText2(text2)} />
                <h2 className="p-2 m-4 text-xl font-semibold text-red-700 shadow-md">RPE</h2>
                <NumberInput onTextChange={(text1) => setEnteredText(text1)} />
                <h2 className="p-2 m-4 text-xl font-semibold text-red-700 shadow-md">Charge de travail</h2>
                <p className="text-2xl font-bold">{getMatrixValue(parseFloat(enteredText), parseInt(enteredText2, 10) - 1, parseFloat(enteredText3))}</p>
            </div>

        );
    } else {
        // Ce que vous voulez afficher si caller n'est pas 'MAX'
        return (
            <div className="App">
                {showExplanation && (
                    <div className="p-4 m-4 text-black bg-red-300 rounded-full shadow">
                        Cette page vous offre la possibilité de déterminer de manière
                        <br />efficace votre max estimé sur un exercice,
                        <br />en se basant sur le nombre de répétitions effectuées
                        <br />à une charge spécifiée et selon un ressenti (RPE).
                    </div>
                )}
                <h2 className="p-2 m-4 text-xl font-semibold text-red-700 shadow-md">Charge de travail

                    <button
                        className="p-1 ml-2 bg-gray-200 rounded-full hover:bg-gray-300 focus:outline-none"
                        onClick={() => setShowExplanation(!showExplanation)}
                    >
                        ?
                    </button>
                </h2>



                <NumberInput onTextChange={(text3) => setEnteredText3(text3)} />
                <h2 className="p-2 m-4 text-xl font-semibold text-red-700 shadow-md">Nombre de répétition</h2>
                <NumberInput onTextChange={(text2) => setEnteredText2(text2)} />
                <h2 className="p-2 m-4 text-xl font-semibold text-red-700 shadow-md">RPE</h2>
                <NumberInput onTextChange={(text1) => setEnteredText(text1)} />
                <h2 className="p-2 m-4 text-xl font-semibold text-red-700 shadow-md" >MAX estimé</h2>
                <p className="text-2xl font-bold">{getMaxestimer(parseFloat(enteredText3), parseFloat(enteredText), parseInt(enteredText2, 10) - 1)}</p>
            </div >

        );
    }



}

export default Calculator;
